#main-menu {
    background-color: $oyat-bg-primary;
    position: sticky;
    top: 0;
    z-index: 500;
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.15);

    // ============================================
    // GLOBAL NAV
    // ============================================

    .nav-item {
        padding: 0;
        margin-bottom: 0;
    }

    .nav-item.active > .nav-link {
        color: lighten($main-color, 20);
    }

    .nav-link {
        text-transform: uppercase;
        color: $main-color;
        font-weight: $weightSemiBold;
        padding: 16px 8px;
        font-size: 14px;
        transition: .2s;

        &:hover {
            color: lighten($main-color, 20);
        }
    }

    .navbar .dropdown-toggle {
        position: relative;
    }

    .hamburger {
        display: flex;
        align-items: center;

        @include vlg {
            display: none;
        }

        .hamburger-box {
            width: 30px;

            .hamburger-inner {
                width: 30px;
                height: 3px;
                background-color: $main-color;

                &:before, &:after {
                    background-color: $main-color;
                    width: 30px;
                    height: 3px;
                }
            }
        }
    }

    // ============================================
    // TOP NAV
    // ============================================

    #topNav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navbar-nav {
            min-width: 350px;
            flex-direction: row;

            i {
                font-size: 22px;
            }

            .nav-icon {
                width: 22px;
            }

            .nav-link {
                padding: 8px;
            }

            .nav-spotify {
                #nav-spotify-button {
                    position: relative;

                    #nav-spotify-dropdown {
                        display: flex;
                        align-items: center;

                        &:after {
                            display: none;
                        }
                    }

                    #nav-spotify-popup {
                        position: absolute;
                        will-change: transform;
                        top: 0px;
                        left: -400px;
                        transition: 0.4s;
                        margin-top: 0;
                        border: none;
                        width: 400px !important;
                        background-color: $oyat-bg-primary;
                        background-image: url('../../media/logo/circle/circle-52.svg');
                        background-repeat: no-repeat;
                        background-position: bottom -60px right -120px;
                        background-size: 75%;
                        color: $oyat-black;
                        box-shadow: $box-shadow;
                        height: 100vh;
                        overflow: scroll;

                        @include media-breakpoint-up(xl) {
                             transform: translate3d(-24px, -16px, 0px) !important;
                        }

                        @include media-breakpoint-down(lg) {
                            transform: translate3d(0px, 0px, 0px) !important;
                        }

                        @include media-breakpoint-down(xs) {
                            left: -280px;
                            width: 280px !important;
                        }

                        .btn-bordered {
                            background-color: $oyat-bg-primary;

                            &:hover {
                                background: $main-color;
                            }
                        }
                    }
                }
            }

            .nav-cart {
                #sylius-cart-button {
                    position: relative;

                    #sylius-cart-badge {
                        display: flex;
                        align-items: center;

                        &:after {
                            display: none;
                        }

                        .badge {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 15px;
                            height: 15px;
                            border-radius: 30px;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 10px;
                        }
                    }

                    #sylius-cart-popup {
                        position: absolute;
                        top: 0px;
                        left: 400px;
                        will-change: transform;
                        transition: 0.4s;
                        margin-top: 0;
                        border: none;
                        height: 100vh;
                        overflow: scroll;
                        width: 400px !important;
                        background-color: $oyat-bg-primary;
                        background-image: url('../../media/logo/circle/circle-52.svg');
                        background-repeat: no-repeat;
                        background-position: bottom -60px right -120px;
                        background-size: 75%;
                        color: $oyat-black;
                        box-shadow: $box-shadow;

                        .popup-cart-product-image {
                            @include media-breakpoint-up(sm) {
                                display: table-cell;
                            }

                            @include media-breakpoint-down(xs) {
                                display: none;
                            }
                        }

                        @include media-breakpoint-up(xl) {
                            transform: translate3d(-226px, -16px, 0px) !important
                        }

                        @include media-breakpoint-down(lg) {
                            transform: translate3d(-256px, 0px, 0px) !important
                        }

                        @include media-breakpoint-down(xs) {
                            left: 280px;
                            width: 280px !important;
                            transform: translate3d(-156px, 0px, 0px) !important
                        }

                        .btn-bordered {
                            background-color: $oyat-bg-primary;

                            &:hover {
                                background: $main-color;
                            }
                        }

                        .popup-cart-price {
                            color: $main-color;
                            font-weight: $weightMedium;
                        }
                    }
                }

                &.active {
                    #sylius-cart-badge {
                        color: lighten($main-color, 20);
                    }
                }
            }
        }
    }

    // ============================================
    // BOTTOM NAV
    // ============================================

    #botNav {
        .navbar-nav {
            padding: 0 15px;

            @media screen and (min-width: 1200px) {
                .nav-item:first-child a {
                    padding-left: 0;
                }

                .nav-item:last-child a {
                    padding-right: 0;
                }
            }
        }

        .sub-navbar-nav {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .sub-navbar-item {
                &:last-child {
                    margin-bottom: 16px;
                }

                a {
                    color: $oyat-black;
                    font-weight: $weightLight;
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: $oyat-bg-primary;

                .nav-link {
                    font-weight: $weightRegular;
                }
            }

            .dropdown-toggle {
                display: flex;
                align-items: center;

                &:after {
                    margin-left: 10px;
                    border: none;
                    content: url('/public/images/icons/chevron-right_small.png');
                    transition: transform 0.3s;
                }
            }

            &:hover {
                .dropdown-toggle:after {
                    transform: rotate(90deg);
                }
            }
        }
    }

    // ============================================
    // DESKTOP NAV
    // ============================================

    @media screen and (min-width: 1200px) {
        .dropdown-wrapper:hover .dropdown-menu {
            display: flex;
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s;
        }

        .dropdown-wrapper:not(:hover) .dropdown-menu {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0s 0.3s;
        }

        .dropdown {
            position: static;

            .dropdown-menu {
                min-height: 153px;
                width: 100%;
                left: 50% !important;
                right: auto !important;
                transform: translateX(-50%) !important;
                margin-top: 0;
                top: 56px;
                display: flex;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.3s, visibility 0s 0.3s;
                box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.15),
                0 20px 20px -20px rgba(0, 0, 0, 0.15);
                border: none;

                @media screen and (min-width: 1200px) {
                    background-image: url("/public/images/brasero_top-view.png");
                    background-repeat: no-repeat;
                    background-position: 100% 100%;
                    // background-size: contain;
                    background-size: 20%;
                }

                .nav-link {
                    font-weight: $weightRegular;
                }
            }
        }
    }

    // ============================================
    // MOBILE NAV
    // ============================================

    @media screen and (max-width: 1199px) {
        #topNav {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .navbar-nav {
                min-width: auto;
                flex-direction: row;

                @media screen and (max-width: 575px) {
                    i {
                        font-size: 18px;
                    }

                    .nav-icon {
                        width: 18px;
                    }
                }
            }

            @media screen and (max-width: 575px) {
                .nav-link {
                    font-size: 12px;
                }
            }

            .nav-link {
                padding: 10px;
            }
        }

        #botNav {
            position: absolute;
            width: 100%;
            top: 62.6px;
            background-color: $oyat-bg-primary;
            overflow: scroll;
            max-height: 80vh;
            box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.15),
            0 20px 20px -20px rgba(0, 0, 0, 0.15);
            border: none;

            .navbar-nav {
                align-items: start;
                margin: 16px;
                padding: 0;

                .nav-link {
                    padding: 0;
                }

                .nav-item:not(:last-child) {
                    margin-bottom: 8px;
                }

                .nav-item:not(:last-child) .nav-link {
                    margin: 0 0 8px 0;
                }

                .dropdown {
                    width: 100%;

                    .dropdown-menu {
                        padding: 0;
                        margin: 0;
                        border: none;

                        .nav-item:not(:last-child) {
                            margin-bottom: 0;
                        }

                        .nav-link {
                            margin: 0 0 8px 0;
                            padding: 0;
                            font-weight: $weightRegular;
                        }

                        .sub-navbar-nav .sub-navbar-item:last-child {
                            margin: 0 0 8px 0;
                        }
                    }

                    &.show {
                        .dropdown-toggle:after {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}
