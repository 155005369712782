.card-item {
    a {
        color: $oyat-black;

        .card-item-image {
            height: 100%;
            margin-bottom: 1rem;
            overflow: hidden;

            .card-item-image-default {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.3s ease;
            }
        }

        .card-item-content {
            .infos {
                color: $main-color;
                font-size: 14px;
                font-weight: $weightLight;
                text-transform: uppercase;

                @include media-breakpoint-down(xs) {
                    font-size: 10px;
                }
            }

            h2 {
                color: $oyat-black;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: $weightMedium;

                @include media-breakpoint-down(xs) {
                    font-size: 14px;
                }
            }

            .description {
                @include media-breakpoint-down(xs) {
                    font-size: 12px;
                }
            }
        }

        &:hover {
            text-decoration: none;
            color: $oyat-black;

            .card-item-image img {
                transform: scale(1.1);
            }
        }
    }
}

.card-presentation {
    display: flex;
    justify-content: center;

    a {
        .card-presentation-content {
            @include media-breakpoint-up(xl) {
                width: 400px;
            }

            .card-presentation-image-title {
                position: relative;

                .card-presentation-image {
                    width: 100%;
                    overflow: hidden;
                    margin-bottom: 48px;

                    img {
                        transition: transform 0.3s ease;
                    }
                }

                .card-presentation-title {
                    padding: 16px;
                    margin-bottom: 0;
                    background-color: $oyat-bg-primary;
                    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    z-index: 1;
                    min-width: 60%;
                    min-height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    bottom: -25px;
                }
            }

            .card-presentation-description {
                color: $oyat-black;
                text-align: left;
                margin-bottom: 0;
            }
        }

        &:hover {
            text-decoration: none;

            .card-presentation-image img {
                transform: scale(1.1);
            }
        }
    }
}

.card-image {
    a {
        position: relative;
        display: block;

        .image {
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: transform 0.3s ease;
            }
        }

        .overlay {
            position: absolute;
            z-index: 300;
            bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            background-color: rgba(black, 0);
            transition: .2s all cubic-bezier(.19, .48, .26, .97);

            &:before {
                content: '';
                position: absolute;
                z-index: 260;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 200px;
                background-image: linear-gradient(to top, rgba(black, .9), rgba(black, 0));
                opacity: 1;

                @include media-breakpoint-down(xs) {
                    height: 100px;
                }
            }

            .overlay-top {
                position: relative;
                z-index: 300;
                text-align: center;
                padding: 40px 16px;
                transition: .2s all cubic-bezier(.19, .48, .26, .97);

                @include media-breakpoint-down(sm) {
                    padding: 20px 16px;
                }

                @include media-breakpoint-down(xs) {
                    padding: 12px;
                }

                & > * {
                    color: white;
                    margin: 0;
                }

                .name {
                    font-size: 18px;
                    font-weight: $weightSemiBold;
                    text-transform: uppercase;

                    @include media-breakpoint-down(xs) {
                        font-size: 14px;
                    }
                }
            }
        }

        &:hover {
            .image img {
                transform: scale(1.1);
            }
        }
    }
}

.card-solid-shadow {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $main-color-light;
        transform: translate(40px, -40px);
        z-index: -1;
        transition: .2s;

        @include media-breakpoint-down(md) {
            content: none;
        }
    }
}

.card-solid-shadow-left {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $main-color-light;
        transform: translate(-40px, -40px);
        z-index: -1;
        transition: .2s;

        @include media-breakpoint-down(md) {
            content: none;
        }
    }
}
