#compatibility-content {
    .product-name {
        font-size: 14px;
        font-weight: $weightRegular;
        color: $main-color;
    }

    .table-responsive {
        max-height: 80vh;

        table {
            min-width: max-content;
            border-collapse: separate;
            border-spacing: 0;

            thead {
                th {
                    padding-top: 0;
                    border-top: 0;
                    position: sticky;
                    top: 0;
                    width: 160px;
                }

                .accessories {
                    border-right: 0 !important;
                    width: 200px;
                    top: 0;
                    z-index: 200;

                    @include media-breakpoint-down(xs) {
                        width: 160px;
                    }
                }
            }

            th {
                background-color: $oyat-bg-primary !important;
            }

            .accessories {
                position: sticky;
                left: 0;
                border-right: 1px solid $border-table-color;
            }

            td {
                text-align: center;
                vertical-align: middle;

                .fas.fa-times {
                    color: #9A0808;
                }
            }
        }
    }
}
