.main-breadcrumb {
    .breadcrumb {
        margin-bottom: 0;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        content: url('/public/images/icons/chevron-right_small.png');
    }

    .breadcrumb-item a {
        color: $main-color;
        font-weight: $weightLight;
    }

    .breadcrumb-item.active {
        color: $main-color;
    }
}
