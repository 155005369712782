//@import '../../../BootstrapTheme/assets/scss/index';

// Third party components
@import '~semantic-ui-css/components/flag.min.css';
@import '~glightbox/dist/css/glightbox.min.css';

@import 'imports';
@import 'imports-globals';

// PAGES
@import 'pages/checkout';
@import "pages/partnership";
@import "pages/test-oyat";
@import "pages/usage";
@import "pages/blog";
@import "pages/faq";
@import "pages/talk-about-us";
@import "pages/about";
@import "pages/live";
@import "pages/register";
@import "login";
@import "pages/reset-password";
@import "pages/recipe";
@import "pages/photo-gallery";
@import "pages/designers";
@import "pages/cart";
@import "pages/account";
@import "pages/unsubscribe-newsletter";
@import "pages/compatibility";
@import "pages/infos-sangria";
@import "pages/relais-du-chateau";

// PARTS
@import 'parts/banner';
@import 'parts/breadcrumb';
@import 'parts/footer';
@import 'parts/globals';
@import 'parts/image-text-row';
@import 'parts/links-sticky';
@import 'parts/main-icones';
@import 'parts/main-menu';
@import 'parts/need-help';
@import 'parts/newsletter';
@import 'parts/product-card';
@import 'parts/see-more';
@import 'parts/slider';
@import 'parts/to-top';
@import 'parts/custom-card';
@import 'parts/modal';
@import 'parts/header-banner';
