#newsletter {
    background-color: $main-color-light;
    overflow: hidden;

    .form-group {
        margin-bottom: 0;
        width: 100%;
    }

    .newsletter-title {
        color: $main-color;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: $weightMedium;
    }

    .text {
        margin-bottom: 0;
        font-weight: $weightRegular;
        color: $oyat-black;

        span {
            &.highlight {
                color: $main-color;
            }
        }
    }

    #newsletter_email {
        background-color: transparent;
        border-radius: 0;
        border-top: none;
        border-right: none;
        border-bottom: 1px solid $oyat-black;
        border-left: none;
        color: $oyat-black;
        padding-left: 6px;
        box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.1);
    }

    .btn-main-color {
        box-shadow: -5px 8px 5px -5px rgba(0, 0, 0, 0.1);
    }
}
