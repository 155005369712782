.partnership {
    margin-top: 0!important;

    #contact-us {
        background-image:  url('../../media/homepage/contact/bois-feu-large.png');
        background-repeat: no-repeat;
        background-size:   cover;
        position:          relative;
        padding:           120px 0;

        &:before {
            content:          '';
            position:         absolute;
            top:              0;
            right:            0;
            bottom:           0;
            left:             0;
            background-color: rgba(black, .6);
            z-index:          10;
        }

        .content {
            position: relative;
            z-index:  20;

            .title {
                font-size:   40px;
                font-weight: $weightBold;
                color:       $oyat-white;

                &:after {
                    background-color: $oyat-white;
                }
            }

            .text {
                font-size:   25px;
                font-weight: $weightLight;
                color:       $oyat-white;
            }
        }
    }
}
