// All Bootstrap styles
@import "./imports/variables";

@import "~bootstrap/scss/bootstrap";

$container-large-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1526px
);

@include _assert-ascending($container-large-max-widths, "$container-large-max-widths");

// Single container class with breakpoint max-widths
.container-large {
    @include make-container();
}

// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint-large, $container-max-width-large in $container-large-max-widths {
    .container-large-#{$breakpoint-large} {
        @extend .container-fluid;
    }

    @include media-breakpoint-up($breakpoint-large, $grid-breakpoints) {
        %responsive-container-large-#{$breakpoint-large} {
            max-width: $container-max-width-large;
        }

        // Extend each breakpoint which is smaller or equal to the current breakpoint
        $extend-breakpoint: true;

        @each $name, $width in $grid-breakpoints {
            @if ($extend-breakpoint) {
                .container-large#{breakpoint-infix($name, $grid-breakpoints)} {
                    @extend %responsive-container-large-#{$breakpoint-large};
                }

                // Once the current breakpoint is reached, stop extending
                @if ($breakpoint-large == $name) {
                    $extend-breakpoint: false;
                }
            }
        }
    }
}

// CUSTOM THEME STYLES
@import "../../../BootstrapTheme/assets/scss/steps";
@import "../../../BootstrapTheme/assets/scss/custom";
@import "~animate.css/animate.min";
@import "./../lib/hamburgers/_sass/hamburgers/hamburgers";

// NOTY
@import "~noty/src/noty";
// @import "~noty/src/themes/mint";
@import "./parts/noty-js-oyat-theme";

// SEMANTIC UI ICONS
@import '~semantic-ui-css/components/icon.min.css';

// FONTS
@import "imports/fonts";

// COOKIE CONSENT
@import '~vanilla-cookieconsent/dist/cookieconsent.css';
:root {
    --cc-bg: #FFFFFF;
    --cc-text: #0B0B0B;
    --cc-btn-primary-bg: #405862;
    //--cc-btn-primary-bg: #405862;
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #405862;
    //--cc-btn-primary-hover-bg: #3A4F58;
    --cc-btn-secondary-bg: #EAEFF2;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #D8E0E6;
    --cc-toggle-bg-off: #919EA6;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #D5DEE2;
    --cc-toggle-knob-bg: #FFFFFF;
    --cc-toggle-knob-icon-color: #ECF2FA;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #F0F4F7;
    --cc-cookie-category-block-bg-hover: #E9EFF4;
    --cc-section-border: #F1F3F5;
    --cc-cookie-table-border: #E9EDF2;
    --cc-overlay-bg: rgba(4, 6, 8, .85);
    --cc-webkit-scrollbar-bg: #CFD5DB;
    --cc-webkit-scrollbar-bg-hover: #9199A0;
}

#cm, #s-inr {
    border-radius: 0;

    button {
        border-radius: 0;
    }

    .c-bl {
        border-radius: 0;
    }
}
