.banner-header {
    text-align: center;
    border:     none !important;
    position:   relative;


    .card-img {
        position:   absolute;
        z-index:    10;
        top:        0;
        height:     0;
        width:      100%;
        height:     100%;
        object-fit: cover;
    }

    video {
        position:   absolute;
        z-index:    10;
        top:        0;
        height:     0;
        width:      100%;
        height:     100%;
        object-fit: cover;
    }

    .card-img-overlay {
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        text-align:      center;
        color:           #FFF;
        background:      transparent linear-gradient(180deg, #00000000 0%, #000 100%) 0% 0% no-repeat padding-box;
        padding-bottom:  2.625rem;
        position:        relative;
        z-index:         20;
        height:          350px;
    }

    h1, p {
        text-align:     center;
        letter-spacing: 6px;
        color:          #FFF;
        margin-bottom:  0;
        letter-spacing: 6px;
        font-size:      50px;
        text-transform: uppercase;
        font-weight:    $weightLight;

        @include xs {
            font-size: 30px;
        }
        @include sm {
        }
        @include md {
        }
        @include lg {
        }
        @include vlg {
        }
    }

}
