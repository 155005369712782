
#need-help {
    position: fixed;
    z-index:  800;
    bottom:   50px;
    right:    100px;

    @include xs {
        bottom: 10px;
        right:  10px;
    }
    @include sm {
        bottom: 10px;
        right:  10px;
    }
    @include md {
    }
    @include lg {
    }
    @include vlg {
    }

    a {
        background-color: black;
        color:            white;
        padding:          20px;
        border-radius:    50px;
        display:          flex;
        align-items:      center;
        box-shadow:       0 5px 10px rgba(black, .4);
        position:         relative;
        z-index:          450;
        transition:       .2s;
        margin:           0;

        @include xs {
            padding: 10px;
        }
        @include sm {
            padding: 10px;
        }
        @include md {
        }
        @include lg {
        }
        @include vlg {
        }

        .text {
            font-size:   20px;
            width:       0;
            opacity:     0;
            overflow:    hidden;
            white-space: nowrap;
            transition:  .3s all cubic-bezier(.74, -0.41, .31, 1.43);
            @include xs {
                font-size: 12px;
            }
            @include sm {
                font-size: 12px;
            }
            @include md {
            }
            @include lg {
            }
            @include vlg {
            }
        }

        .icon {
            font-size:   45px;
            line-height: 45px;
            width:       45px;
            height:      45px;
            animation:   5s infinite swingCustom;
            @include xs {
                font-size:   20px;
                line-height: 20px;
                width:       20px;
                height:      20px;
                svg {
                    height: 20px;
                    width:  20px;
                }
            }
            @include sm {
                font-size:   20px;
                line-height: 20px;
                width:       20px;
                height:      20px;
                svg {
                    height: 20px;
                    width:  20px;
                }
            }
            @include md {
            }
            @include lg {
            }
            @include vlg {
            }
        }

        &:hover {
            text-decoration: none;
            padding-left:    40px;

            .text {
                opacity: 1;
                width:   150px;
                @include xs {
                    width: 100px;
                }
                @include sm {
                    width: 100px;
                }
                @include md {
                }
                @include lg {
                }
                @include vlg {
                }
            }
        }


    }

    &:before {
        content:          '';
        position:         absolute;
        top:              50%;
        left:             50%;
        transform:        translate(-50%, -50%);
        border-radius:    100px;
        background-color: black;
        z-index:          400;
    }

    &:hover {
        &:before {
            animation: .8s linear infinite needHelpPusle;
        }
    }
}

@keyframes swingCustom {

    5% {
        transform: rotate(15deg);
    }
    10% {
        transform: rotate(-10deg);
    }
    15% {
        transform: rotate(5deg);
    }
    20% {
        transform: rotate(-5deg);
    }
    25% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}


@keyframes needHelpPusle {
    0% {
        width:   calc(100%);
        height:  calc(100%);
        opacity: .3;
    }
    50% {
        width:   calc(100% + 25px);
        height:  calc(100% + 25px);
        opacity: .2;
    }
    100% {
        width:   calc(100% + 50px);
        height:  calc(100% + 50px);
        opacity: 0;
    }
}
