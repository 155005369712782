.more-items {
    display: none;
}

.see-more {
    text-transform: uppercase;
    color: $main-color;
    font-size: 20px;
    font-weight: $weightMedium;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(xs) {
        font-size: 18px;
    }

    &:hover {
        cursor: pointer;

        &:not(.active):after, &.active:before {
            transform: translateY(0) scale(0.7);
        }
    }
}

.see-more.active:before {
    content: url('/public/images/icons/chevron-top.png');
    transform: translateY(5px) scale(.6);
    transition: .2s;
}

.see-more:not(.active):after {
    content: url('/public/images/icons/chevron-bottom.png');
    transform: translateY(-5px) scale(.6);
    transition: .2s;
}

.see-more.active:after {
    display: none;
}
