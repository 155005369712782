#to-top {
    text-align: center;

    .to-top-content {
        text-align: center;
        text-transform: uppercase;
        color: $main-color;
        font-size: 20px;
        font-weight: $weightMedium;
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(xs) {
            font-size: 18px;
        }

        &:before {
            content: url('../../../../../public/images/icons/chevron-top.png');
            transition: .2s;
            transform: translateY(0) scale(.6);
        }

        &:hover {
            cursor: pointer;

            &:before {
                transform: translateY(-5px) scale(0.7);
            }
        }
    }
}
