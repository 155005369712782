#infos-oyat-container {
    .icone {
        text-align: center;
        color: $oyat-black;

        img {
            margin-bottom: 16px;
        }

        .title {
            font-weight: $weightRegular;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .description {
            line-height: 24px;
        }
    }

    @media screen and (max-width: 575px) {
        img {
            max-width: 40px;
        }

        .title, .description {
            font-size: 14px;
        }
    }
}
