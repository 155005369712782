#footer {
    .footer-nav {
        .social-networks {
            width: 180px;
        }

        .footer-nav-title {
            font-size: 16px;
            font-weight: $weightBold;
            color: $main-color;
            text-transform: uppercase;
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;

            a {
                color: $oyat-black;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .copyright {
        color: $oyat-black;
        font-size: 14px;
        text-align: center;
        margin-top: 1.5rem;
    }
}
