.carousel-black-arrow {
    position: relative;

    .slick-arrow {
        $size:            64px;
        position:         absolute;
        width:            $size;
        height:           $size;
        top:              50%;
        transform:        translateY(-50%);
        z-index:          50;
        background-color: $main-color;
        transition:       .1s;
        opacity:          .5;

        &:focus {
            background-color: $main-color;
        }

        &:before {
            font-size:   24px;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color:       white;
            opacity:     1;
            display:     inline-block;
            transition:  .1s;
        }

        &.slick-prev {
            left: 0;

            &:before {
                content: "\f053";
            }

            &:hover {
                &:before {
                    transform: translateX(-5px);
                }
            }
        }

        &.slick-next {
            right: 0;

            &:before {
                content: "\f054";
            }

            &:hover {
                &:before {
                    transform: translateX(5px);
                }
            }
        }

        &:hover {
            background-color: $main-color;
        }

        &.slick-disabled {
            opacity:          .3 !important;
            cursor:           default;
        }
    }

    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }
}

.slick-product-discovery-carousel {
    .slick-arrow {
        z-index: 10;
    }

    .slick-prev {
        &:before {
            content: url("/public/images/icons/chevron-left.png");
        }
    }

    .slick-next {
        &:before {
            content: url("/public/images/icons/chevron-right.png");
        }
    }

    .slick-dots {
        bottom: -40px;
    }

    .slick-dots li button::before {
        font-size: 10px;
        color: $main-color;
    }

    .slick-slide {
        padding: 0 20px;

        img {
            max-height: 300px;
        }
    }

    @include media-breakpoint-down(xs) {
        .slick-slide {
            padding: 0 10px;
        }
    }

    .slick-prev {
        @include media-breakpoint-up(lg) {
            left: -40px;
        }
    }

    .slick-next {
        @include media-breakpoint-up(lg) {
            right: -40px;
        }
    }
}
