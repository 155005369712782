#designers-content {
    .card-presentation {
        .card-presentation-content {
            @include media-breakpoint-down(xs) {
                display: block;
                margin-bottom: 0;
                position: initial;
            }
        }
        .card-presentation-title {
            bottom: -60px;
            min-height: 120px;
            min-width: 80%;
            color: $oyat-black;

            @include media-breakpoint-down(xs) {
                position: initial;
                min-width: auto;
                transform: initial;
                padding: 8px;
                font-size: 14px;
            }

            .designer-name {
                font-weight: $weightMedium;
                margin-bottom: 0.5rem;
            }

            .speciality {
                font-style: italic;
                margin-bottom: 0;
            }
        }
    }

    .french-design {
        background-color: $main-color-light;
    }
}

#designer-content {
    .creation-section {
        a {
            text-decoration: none;
        }
        .product-name {
            color: $main-color;
            font-weight: $weightMedium;
            margin-bottom: 0;
        }
    }

}
