#usage-content {
    .usage-section {
        @include media-breakpoint-up(md) {
            .progressbar {
                width: 100%;
            }

            .progressbar li {
                list-style-type: none;
                width: 20%;
                float: left;
                font-size: 14px;
                position: relative;
                text-align: center;
                color: $main-color;
                cursor: pointer;
            }

            .progressbar li:after {
                width: 15px;
                height: 15px;
                content: '';
                line-height: 30px;
                border: 1px solid rgba(64, 88, 98, 0.5);
                background-color: $oyat-bg-primary;
                display: block;
                text-align: center;
                margin: 10px auto 0 auto;
                border-radius: 50%;
                transition: all 0.5s ease-in-out;
            }

            .progressbar li:before {
                width: 80%;
                height: 1px;
                content: '';
                position: absolute;
                background-color: $main-color;
                top: 40px;
                left: -40%;
                z-index: -1;
                opacity: 0.5;
                transition: opacity 0.5s ease-in-out;
            }

            .progressbar li:first-child:before {
                display: none;
            }

            .progressbar li.active:after {
                border: 1px solid rgba(64, 88, 98, 1);
                background-color: $main-color;
                transition: all 0.5s ease-in-out;
            }

            .progressbar li.active:before {
                top: 39px;
                height: 3px;
                opacity: 1;
                transition: opacity 0.5s ease-in-out;
            }
        }

        .slick-carousel-usage {
            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }

            .slick-arrow {
                z-index: 10;
            }

            .slick-prev {
                left: 10px;

                @include media-breakpoint-down(xl) {
                    left: -30px;
                }

                @include media-breakpoint-down(xs) {
                    left: 0;
                }

                &:before {
                    content: url("/public/images/icons/chevron-left.png");
                }
            }

            .slick-next {
                right: 10px;

                @include media-breakpoint-down(xl) {
                    right: -30px;
                }

                @include media-breakpoint-down(xs) {
                    right: 0;
                }

                &:before {
                    content: url("/public/images/icons/chevron-right.png");
                }
            }

            @include media-breakpoint-down(sm) {
                .slick-next, .slick-prev {
                    top: 1%;
                }
            }

            @include media-breakpoint-down(xs) {
                .slick-slide {
                    padding: 0;
                }
            }
        }
    }

    #entretien, #cuisson, #utilisation {
        padding-top: 163px !important;
        margin-top: -163px !important;
    }
}
