#live-content {
    .live-list {
        .card-item {
            .card-item-image {
                position: relative;

                .live-card-image-icon {
                    position: absolute;
                    bottom: 5%;
                    right: 5%;
                    transition: transform 0.3s ease;
                }
            }
        }
    }

    .more-items .live-list {
        margin-top: 15px;
    }

    .social-media {
        img {
            transition: .2s;

            &:hover {
                transform: translateY(-5px);
            }
        }
    }
}
