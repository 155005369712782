#cart-content {
    #sylius-cart-items {
        min-width: 600px;

        .sylius-product-name {
            color: $oyat-black;
            margin-bottom: 0;
        }

        .sylius-quantity input {
            border-top: none;
            border-right: none;
            border-bottom: 1px solid $border-table-color;
            border-left: none;
        }

        .card-header th {
            font-weight: $weightRegular;
        }

        th {
            background-color: $oyat-bg-primary !important;
            border-top: none;
            border-bottom: 1px solid $border-table-color;
        }

        td {
            padding: 1.5rem 0.75rem;
            border-top: none;
            border-bottom: 1px solid $border-table-color;
        }

        .media {
            align-items: center;
        }
    }

    .link-update-cart {
        background-color: transparent;
        border: none;
        color: $main-color;
        font-size: 16px;
    }

    .input-group {
        width: fit-content;

        #sylius_cart_promotionCoupon {
            border-color: $border-table-color;
        }
    }

    .cart-summary {
        border: 1px solid $border-table-color;

        .label-bold {
            font-weight: $weightMedium;
        }
    }

    #paypal-button-container {
        margin-top: 24px;
        display: flex;
        justify-content: center;

        .paypal-buttons-context-iframe {
            text-align: center;
            width: fit-content;
        }
    }
}
