#blog-content {
    .hidden {
        display: none;
    }

    .category-link.active {
        text-decoration: underline;
    }
}

#article-content {
    .article-intro {
        background-color: $main-color-light;
        text-align: center;

        .infos {
            color: $main-color;
            font-size: 14px;
            font-weight: $weightLight;
            text-transform: uppercase;
        }
    }
}
