$custom-about-spacing: 96px;

#about-content {
    &.bg-circle {
        &:before {
            top: 800px;
            height: 700px;
        }
    }

    .custom-spacing {
        margin-bottom: $custom-about-spacing !important;
    }

    #notre-savoir-faire {

        @include media-breakpoint-down(xs) {
            .slick-carousel-about-expertise-descriptions {
                .slick-prev, .slick-next {
                    top: 1%;
                }

                .slick-prev {
                    left: 0;
                }

                .slick-next {
                    right: 0;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .slick-carousel-about-expertise-images {
                .slick-slide {
                    text-align: center;
                }

                img {
                    width: 395px !important;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .slick-carousel-about-expertise-images {
                .slick-slide {
                    transform: translate(100%, 0);
                }

                .slick-slide:not(.slick-current) {
                    img {
                        filter: grayscale(1);
                        transform: scale(0.75);
                        opacity: 0.5
                    }
                }

                .slick-arrow {
                    z-index: 10;
                }

                .slick-next {
                    right: 14%;

                    &:before {
                        content: url("/public/images/icons/chevron-right.png");
                    }
                }

                .slick-prev {
                    left: 14%;

                    &:before {
                        content: url("/public/images/icons/chevron-left.png");
                    }
                }

                .slick-dots {
                    bottom: -40px;
                }

                .slick-dots li button::before {
                    font-size: 10px;
                    color: $main-color;
                }
            }
        }

        @include media-breakpoint-down(md) {
            @include media-breakpoint-only(md) {
                .slick-prev {
                    left: -35px;
                }

                .slick-next {
                    right: -35px;
                }
            }

            .slick-arrow {
                z-index: 10;
            }

            .slick-prev {
                &:before {
                    content: url("/public/images/icons/chevron-left.png");
                }
            }

            .slick-next {
                &:before {
                    content: url("/public/images/icons/chevron-right.png");
                }
            }

            .slick-dots li button::before {
                font-size: 10px;
                color: $main-color;
            }
        }

        .slick-dotted.slick-slider {
            margin-bottom: 40px;

            .slick-dots {
                bottom: -40px;
            }
        }
    }

    #nos-engagements {
        .materials {
            background-color: $main-color-light;
            position: relative;

            .materials-imgs {
                @include media-breakpoint-up(md) {
                    bottom: calc(100% - 35px); // - la moitié de la hauteur des ronds
                }

                @include media-breakpoint-down(sm) {
                    bottom: calc(100% - 30px);
                }

                @include media-breakpoint-up(sm) {
                    width: 100%;
                    position: absolute;
                }

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    box-shadow: 0px 3px 1px #ccc;

                    @include media-breakpoint-down(sm) {
                        width: 60px;
                        height: 60px;
                    }

                    @include media-breakpoint-down(xs) {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .slick-carousel-eco-conception {
            .slick-next {
                right: 14%;

                &:before {
                    content: url("/public/images/icons/chevron-right.png");
                }
            }

            .slick-prev {
                left: 14%;

                &:before {
                    content: url("/public/images/icons/chevron-left.png");
                }
            }

            .slick-arrow {
                z-index: 10;
            }

            .slide {
                .number {
                    color: #D9CDBD;
                    font-weight: $weightMedium;
                    font-size: 34px;
                }
            }

            @include media-breakpoint-down(md) {
                max-width: 720px;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;

                .slick-next {
                    right: 0;
                }

                .slick-prev {
                    left: 0;
                }

                .slick-slide {
                    padding: 0 30px;
                }
            }

            @include media-breakpoint-down(sm) {
                max-width: 540px;
            }

            @include media-breakpoint-up(lg) {
                .slick-track {
                    display: flex !important;
                }

                .slick-slide {
                    transform: translate(100%, 0);
                    height: inherit !important;

                    @include media-breakpoint-up(xl) {
                        padding: 0 80px;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0 50px;
                    }
                }

                .slick-slide {
                    transform: translate(100%, 0);
                    border-left: 1px solid #D9CDBD;
                    border-right: 1px solid #D9CDBD;
                }

                .slick-slide:not(.slick-current) {
                    opacity: 0.2;
                }
            }
        }
    }
}
