#checkout-steps {
    .steps-item {
        display: flex;
        justify-content: center;
        position: relative;
        border-top: none !important;
        border-right: none !important;
        border-bottom: none !important;
        border-left: none !important;

        &.active {
            background: none;

            .steps-title {
                color: $main-color;
            }

            .steps-content:before {
                height: 3px;
                opacity: 1;
                top: 63px;
            }

            .steps-content:after {
                border: 1px solid rgba(64, 88, 98, 1);
                background-color: $main-color;
                transition: all 0.5s ease-in-out;
            }
        }

        &.completed {
            background: none;

            .steps-content:before {
                height: 3px;
                opacity: 1;
                top: 63px;
            }

            .steps-content:after {
                background-color: $main-color;
            }
        }

        &.disabled {
            background-color: transparent;
        }

        &:first-child .steps-content:before {
            display: none;
        }

        .steps-content {
            .steps-title {
                color: $main-color;
                margin-bottom: 0;
            }

            &:before {
                width: 80%;
                height: 1px;
                content: '';
                position: absolute;
                background-color: $main-color;
                top: 64px;
                left: -40%;
                z-index: -1;
                opacity: 0.5;
            }

            &:after {
                width: 15px;
                height: 15px;
                content: '';
                line-height: 30px;
                border: 1px solid rgba(64, 88, 98, 0.5);
                background-color: $oyat-bg-primary;
                display: block;
                text-align: center;
                margin: 10px auto 0 auto;
                border-radius: 50%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: flex;

        .steps-item {
            padding: 10px;

            .steps-content {
                &:before {
                    top: 54px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        display: flex;

        .steps-item {
            padding: 0;

            .steps-content {
                .steps-title {
                    font-size: 13px;
                }

                &:before {
                    top: 40px;
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: fit-content;

        .steps-content {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .steps-title {
                font-size: 14px !important;
            }

            &:before {
                display: none;
            }

            &:after {
                margin: 0 10px 0 0 !important;
            }
        }
    }
}

#sylius-checkout-subtotal {
    min-width: 348px;
    margin-bottom: 0;

    tr {
        border: 1px solid $border-table-color;
    }

    th {
        border-bottom: 1px solid $border-table-color;
        border-top: 1px solid $border-table-color;
    }

    tbody {
        font-size: 14px;
    }

    tfoot {
        font-size: 14px;

        .h3 {
            margin-bottom: 0;
            text-transform: inherit;
            font-size: 16px;
        }
    }
}

#checkout-address-content {
    .dropdown-menu.show {
        background-color: $oyat-bg-primary;
        border: 1px solid $border-table-color;
    }

    #sylius-billing-address, #sylius-shipping-address {
        input, select {
            border-color: $border-table-color;
        }

        .form-check-input {
            color: $main-color;
            border-color: $main-color;
            margin-top: 4px;

            &:before {
                top: -1px;
                left: 2px;
            }
        }
    }
}

#checkout-select-shipping-content,
#checkout-payment-content,
#checkout-summary-order-content {
    .list-group-item {
        background-color: transparent;
        border: none;
        padding: 0;
    }

    .form-check-input {
        margin-top: 5px;

        &:before {
            top: -1px;
        }
    }

    @include media-breakpoint-down(xs) {
        .shipping-method-money-mobile {
            padding-left: 32px;
        }
    }
}

#checkout-complete-content {
    .card {
        background-color: transparent;
        border-color: $border-table-color;

        h3 {
            font-weight: $weightMedium;
            text-transform: uppercase;
            font-size: 16px;
        }

        address {
            margin-bottom: 0;
        }
    }

    .add-notes {
        .form-group {
            margin-bottom: 0;
        }
    }

    #sylius-order {
        min-width: 450px;

        th {
            background-color: transparent !important;
        }

        thead, tbody, tfoot {
            border: 1px solid $border-table-color;
        }

        tfoot th {
            border: 1px solid $border-table-color;
        }

        .media {
            align-items: center;
        }

        #subtotal {
            font-weight: $weightRegular;
        }
    }

    textarea {
        border-color: $border-table-color;
    }

    .label-bold {
        font-weight: $weightMedium;
    }
}
