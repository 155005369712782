body {
    font-family: 'Jost';
    min-width: 320px;
    letter-spacing: 1px;
    color: $oyat-black;
    font-size: 0.98rem;

    .grecaptcha-badge {
        visibility: hidden;
    }

    #fb-root {
        z-index: 450;
    }

    main {
        &.main-content {
            min-height: calc(100vh - 80px - 342px);

            .noty-container {
                margin: 0;
            }
        }
    }

    .hidden {
        display: none;
    }

    .price, #product-price {
        color: $main-color;
    }

    // =============================================================
    //                          FORM
    // =============================================================

    .sylius-quantity input {
        text-align: center;
    }

    // =============================================================
    //                          CIRCLES
    // =============================================================

    @include media-breakpoint-up(lg) {
        .bg-circle {
            position: relative;
            overflow: hidden;

            &:before {
                content: '';
                background-image: url('../../media/logo/circle/circle-52.svg');
                background-repeat: no-repeat;
                background-size: auto 100%;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -10;
                height: 100%;
                transform: translateX(50%);
                aspect-ratio: 1 /1;
            }

            &.bg-circle-left {
                &:before {
                    right: auto;
                    left: 0;
                    transform: translateX(-50%) rotate(180deg);
                    z-index: -10;
                }
            }
        }
    }

    // =============================================================
    //                          TITLES
    // =============================================================

    .main-title {
        letter-spacing: 6px;
        font-size: 30px;
        font-weight: $weightBold;
        text-transform: uppercase;
        color: $main-color;
    }

    .title-underline {
        position: relative;
        margin-bottom: 50px;

        &:after {
            content: '';
            position: absolute;
            bottom: -25px;
            left: 0;
            width: 75px;
            height: 8px;
            background-color: $main-color;
        }

        &.text-center {
            &:after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.text-right {
            &:after {
                left: auto;
                right: 0;
            }
        }
    }

    .title-logo {
        position: relative;
        z-index: 10;
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:before {
            content: '';
            background-image: url('../../media/logo/circle/circle-48.svg');
            background-repeat: no-repeat;
            background-size: cover;
            width: 80px;
            height: 80px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
        }

        & > span {
            position: relative;
            z-index: 10;
        }


        &.text-left {
            justify-content: flex-start;

            &:before {
                left: 0;
                transform: translate(-27%, -50%);
            }
        }

        &.text-right {
            justify-content: flex-end;

            &:before {
                left: auto;
                right: 0;
                transform: translate(27%, -50%);
            }
        }
    }

    .h1 {
        letter-spacing: 4px;
        font-size: 28px;
        font-weight: $weightMedium;
        text-transform: uppercase;
        color: $main-color;
    }

    .h2 {
        letter-spacing: 2px;
        font-size: 24px;
        font-weight: $weightMedium;
        text-transform: uppercase;
        color: $main-color;
    }

    .h3 {
        letter-spacing: 2px;
        font-size: 18px;
        font-weight: $weightMedium;
        text-transform: uppercase;
        color: $main-color;
    }

    .h4 {
        letter-spacing: 2px;
        font-size: 16px;
        color: $oyat-black;
        font-weight: $weightMedium;
        text-transform: uppercase;
    }

    // =============================================================
    //                          BUTTONS
    // =============================================================

    .btn {
        font-weight: $weightMedium;
        text-transform: uppercase;
        padding: 6px 34px;
        letter-spacing: 0.5px;

        &.btn-rounded {
            border-radius: 800000px;
        }

        &.btn-lg {
            padding: 1rem;

            &:not(.btn-block) {
                padding: 1rem 1.8rem;
            }
        }

        &.btn-primary, &.btn-main-color {
            color: white;
        }

        &.btn-white {
            background-color: $white;
            color: $oyat-black;
            box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.1);

            &:hover {
                color: $main-color;
            }
        }

        &.btn-bordered {
            color: $oyat-black;
            border: 1px solid $main-color;
            box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.1);

            &:hover {
                color: white;
                border: 1px solid $main-color;
                background-color: $main-color;
                box-shadow: none;
            }
        }

        &.btn-outline-main-color, &.btn-outline-primary {
            &:hover {
                color: white;
            }
        }

        &.btn-inverse-outline-main-color {
            color: white;
            background-color: $main-color;

            &:hover {
                background-color: transparent;
                color: $main-color;
                border: 1px solid $main-color;
            }
        }
    }

    .btn-icon {
        padding-right: 5px;
        padding-left: 5px;
    }

    // =============================================================
    //                          TABLE
    // =============================================================

    .table {
        color: $oyat-black !important;
        background-color: $oyat-bg-primary;
    }

    .table th {
        border-bottom: 1px;
        font-weight: $weightMedium;
        background-color: $main-color-light !important;
    }

    .table th, .table td {
        background-color: $oyat-bg-primary;
    }

    // =============================================================
    //                          CONTAINER
    // =============================================================

    @media (min-width: 1600px) {
        .container {
            max-width: 1400px;
        }
    }

    // =============================================================
    //                          GUTTERS
    // =============================================================

    .custom-gutters {
        @include media-breakpoint-up(xl) {
            margin: -30px;
        }

        @include media-breakpoint-down(lg) {
            margin: -15px;
        }

        @include media-breakpoint-down(xs) {
            margin: -8px;
        }

        .col {
            @include media-breakpoint-up(xl) {
                padding: 30px;
            }

            @include media-breakpoint-down(lg) {
                padding: 15px;
            }

            @include media-breakpoint-down(xs) {
                padding: 8px;
            }
        }
    }

    // =============================================================
    //                          MODAL
    // =============================================================

    .modal {
        .modal-content {
            background-color: $oyat-bg-primary;
        }

        .modal-header {
            border: none;
        }
    }

    // =============================================================
    //                          OLD
    // =============================================================

    /* .pagination {
        margin-top: 3rem;
        .page-item.disabled {
            display: none;
        }
        .page-item {
            &.active .page-link {
                background: #000 0% 0% no-repeat padding-box;
                border: 0;
                color: #FFF;
            }
            &:not(:first-child) {
                margin-left: 4px;
            }
            &:not(:last-child) {
                margin-right: 4px;
            }

            &.next .page-link,
            &.prev .page-link {
                background-color: inherit;
                border: 0;
                color: #000;
            }
        }
        .c {
            text-align: center;
            font: normal normal bold 14px/16px 'Josefin Sans';
            letter-spacing: 2.1px;
            color: #C9C9C9;
        }
    } */
}
