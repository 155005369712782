#recipes-content {
    .catchphrase {
        font-weight: $weightMedium;
    }

    .hidden {
        display: none;
    }

    .category-link.active {
        text-decoration: underline;
    }
}

#recipe-content {
    .slider-container {
        position: relative;

        @include media-breakpoint-up('xl') {
            width: 550px;
        }

        @media (min-width: 1200px) and (max-width: 1599px) {
            width: 507px;
        }

        .slick-carousel-recipe {
            .slick-arrow {
                z-index: 10;
            }

            .slick-next {
                right: 10px;

                &:before {
                    content: url("/public/images/icons/chevron-white-right_small.png");
                }
            }

            .slick-prev {
                left: 10px;

                &:before {
                    content: url("/public/images/icons/chevron-white-left_small.png");
                }
            }

            .slick-dots {
                bottom: -40px;
            }

            .slick-dots li button::before {
                font-size: 10px;
                color: $main-color;
            }
        }

        .recipe-favorite-container {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 60;
            font-size: 20px;
            color: $main-color;
            width: 35px;
            height: 32px;

            .favorite, .favorite-link {
                .unselected, .selected {
                    // transition: .2s;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $oyat-bg-primary;
                    border-radius: 100%;
                    padding: 7px 7px 5px 7px;
                }

                .selected {
                    opacity: 0;
                }

                &.active, &:hover {
                    cursor: pointer;

                    .unselected {
                        opacity: 0;
                    }

                    .selected {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .number-indicator {
        font-weight: $weightMedium;
    }

    .ingredients-content {
        ul {
            padding-left: 16px;
        }
    }

    .recipe-reviews {

        .review-info {
            margin-bottom: 0;
            font-size: 12px;
            color: #404040;
        }

        .more-items > div:first-child {
            margin: 0 !important;
        }

        // ========= overwrite see-more
        .see-more {
            font-size: 16px;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            transition: .2s;

            &:hover {
                transform: translateY(5px);
            }
        }

        .see-more:not(.active)::after {
            transform: translateY(0px) scale(.6);
        }

        .see-more.active:after {
            display: inline;
            content: url('/public/images/icons/chevron-top.png');
            transform: translateY(0px) scale(.6);
        }

        .see-more.active:hover {
            transform: translateY(-5px);
        }

        .see-more.active:before {
            display: none;
        }
        // overwrite see-more =========

        .glightbox:hover {
            text-decoration: none;
        }
    }

    .buttons-add-comment-and-more {
        @include media-breakpoint-down('sm') {
            .btn {
                font-size: 14px;
                padding: 6px 12px;
            }
        }
    }
}

#recipeReviewModal {
    .form-control[readonly]:focus {
        background-color: #E9ECEF;
    }

    legend {
        font-weight: 300;
        padding: 0;
        margin-bottom: 2px;
    }

    .rating-stars {
        legend {
            &::after {
                content: "*";
                display: inline-block;
                padding-left: 4px;
                color: #DC3545;
            }
        }

        .rating .form-check:first-child {
            padding-left: 0;
        }

        .rating .form-check:not(:first-child) {
            padding-left: 0.5rem;
        }

        .rating .form-check > input {
            display: none;
        }

        .rating .form-check > label {
            width: 20px;
            height: 20px;
            background-image: url("/public/images/icons/etoile-grise.png");
            background-size: contain;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            text-indent: -9999px;

            &::after {
                content: '' !important;
            }
        }

        .rating .form-check > label.active {
            background-image: url("/public/images/icons/etoile-bleue.png");
            background-size: contain;
            background-repeat: no-repeat;
        }

        .rating .form-check > label.checked {
            background-image: url("/public/images/icons/etoile-bleue.png");
            background-size: contain;
            background-repeat: no-repeat;
        }

        .rating .form-check > label.checked:hover {
            filter: brightness(180%);
        }
    }

    .recipe-review-images {
        .form-group {
            margin-bottom: 0;
        }

        #app_recipe_review_images {
            legend {
                display: none;
            }

            .form-group > label {
                display: none;
            }
        }

        .image-container {
            display: flex;
            margin-bottom: 12px;

            fieldset {
                width: 100%;
                margin-right: 10px;
            }

            .custom-file-label {
                border: 1px solid $main-color;
            }
        }
    }
}
