@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

#talk-about-us-content {
    .carousel {
        .carousel-black-arrow .slick-arrow {
            background-color: transparent;
            opacity: 1;
        }

        .carousel-black-arrow .slick-next {
            right: -40px;

            @include media-breakpoint-down('xs') {
                right: 0;
            }

            &:before {
                transform: translateX(0) scale(.8);
                content: url('../../../../../public/images/icons/chevron-right.png')
            }

            &:hover {
                &:before {
                    transform: translateX(5px) scale(.9);
                    content: url('../../../../../public/images/icons/chevron-right.png')
                }
            }
        }

        .carousel-black-arrow .slick-prev {
            left: -40px;

            @include media-breakpoint-down('xs') {
                left: 0;
            }

            &:before {
                transform: translateX(0) scale(.8);
                content: url('../../../../../public/images/icons/chevron-left.png')
            }

            &:hover {
                &:before {
                    transform: translateX(-5px) scale(.9);
                    content: url('../../../../../public/images/icons/chevron-left.png')
                }
            }
        }

        .slick-initialized .slick-slide {
            padding: 0 30px;

            @include media-breakpoint-down('xs') {
                padding: 0 50px;
            }
        }

        .item a:hover {
            text-decoration: none;

            img {
                transform: scale(1.1);
            }
        }

        .card {
            border: none;
            height: 400px;
            @include media-breakpoint-down('md') {
                height: 370px;
            }
            position: relative;
            overflow: hidden;

            .card-image img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                transition: transform 0.3s ease;
            }

            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: end;
                padding: 0;

                .card-body-content {
                    min-height: 137px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0 0 0));
                    padding: 1.25rem;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        right: 1.25rem;
                        transform: translateY(-50%);
                    }

                    h2 {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: $weightBold;
                        color: $oyat-white;
                        text-shadow: 1px 1px 10px #000;
                        line-height: 1.5;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 0;
                        color: $oyat-white;
                    }
                }
            }
        }
    }
}
