.modal {
    .modal-header {
        position: relative;

        .close {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
}
