#header-banners {
    position: sticky;
    top: 0;
    z-index: 500;

    .banner {
        text-align: center;
        background-color: $oyat-black;
        color: $oyat-white;

        p {
            margin-bottom: 0;
        }

        a {
            color: $white;
            font-weight: bold;
        }
    }
}
