$text-spacer: 40px;

.image-text-row {
    .text {
        padding-left: $text-spacer;
        @include media-breakpoint-down(md) {
            padding: 15px;
        }

        & > *:last-child{
            margin-bottom: 0;
        }
    }

    &-reverse {
        .text {
            text-align:    right;
            padding-left:  0;
            padding-right: $text-spacer;
            @include media-breakpoint-down(md) {
                padding:    15px;
                text-align: left;
            }
        }
    }
}
