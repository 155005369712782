@include media-breakpoint-up(lg) {
    .dropdown-menu .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: 0;
    }
}

.logo {
    @include media-breakpoint-up(md) {
        width: 160px;
    }

    @include media-breakpoint-down(sm) {
        width: 130px;
    }
}

// .main-content > :first-child:not(nav):not(.nav) {
//     margin-top: map-get($spacers, 4);
// }

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
}

.loadable.loading .loading-overlay {
    display: flex;
}


// =============================================================
//                          FORMS
// =============================================================
.form-control {
    border-color: $main-color;
    background-color: transparent;
    letter-spacing: 0.5px;
    color: $oyat-black;
    font-size: 16px;

    &::placeholder {
        color: $oyat-black;
        font-weight: $weightLight;
        font-size: 16px;
        letter-spacing: 1px;
    }

    &:focus {
        background-color: transparent;
        color: $oyat-black;
    }
}
label {
    font-weight: $font-weight-light;
    margin-bottom: 2px;
}
label.required {
    &::after {
        content: '*';
        display: inline-block;
        padding-left: 4px;
        color: $danger;
    }
}

.form-check {
    padding-left: 2rem;

    .form-check-input {
        margin-left: -2rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        border-radius: 2px;
        height: 16px;
        width: 16px;
        background: transparent;
        border: 1px solid $main-color;
        margin-top: 8px;

        &:checked {
            background: $main-color;

            &:before {
                position: absolute;
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                top: -1px;
                left: 2px;
                color: white;
                font-size: 10px;
            }
        }
    }
}
