#sticky-phone {
    position: fixed;
    bottom: 94px;
    right: 24px;
    z-index: 100;

    .tel-pc, .tel-mobile {
        background-color: $main-color;
        color: $oyat-white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 17px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        border-radius: 50px;
        width: 60px;
        height: 60px;
        overflow: hidden;

        i {
            font-size: 25px;
        }

        .phone-number {
            visibility: hidden;
            opacity: 0;
        }

        &.activated {
            width: 240px;
            transition: width 0.2s;

            .phone-number {
                visibility: visible;
                opacity: 1;
                transition-delay: 0.2s;
                transition-property: opacity;
                transition-duration: 0.1s;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }


    @include media-breakpoint-up(lg) {
        .tel-mobile {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        .tel-pc {
            display: none;
        }
    }
}
