#infos-sangria {
    table {
        background-color: transparent !important;

        td {
            background-color: transparent !important;
            vertical-align: middle !important;
        }
    }

    &:before {
        height: 600px;
        top: unset;
        bottom: 0;
    }

    .first-column-primary, .first-column-secondary {
        text-transform: uppercase;
        font-weight: 500;
    }

    .first-column-primary {
        color: $main-color;
    }

    .first-column-secondary {
        padding-left: 80px;
    }
}
