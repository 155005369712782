#photo-gallery-content {
    .card-image {
        height: 100%;

        a {
            height: 100%;

            .image {
                box-shadow: none;
                height: 100%;
            }
        }

        .overlay {
            &:before {
                height: 100%;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
            }

            .overlay-top {
                padding: 40px 16px 120px 16px;

                @include media-breakpoint-down(xl) {
                    padding: 40px 16px 80px 16px;
                }

                @include media-breakpoint-down(md) {
                    padding: 30px 8px;
                }

                @include media-breakpoint-down(sm) {
                    img {
                        width: 26px;
                    }
                }

                .name {
                    text-decoration: underline;

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .gallery {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(20, 5vw);
        grid-gap: 40px;

        @include media-breakpoint-down(lg) {
            grid-template-rows: repeat(24, 5vw);
        }

        @include media-breakpoint-down(sm) {
            grid-gap: 20px;
        }

        .gallery-image-container {
            overflow: hidden;

            .gallery-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .gallery__item--1 {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 4;

            @include media-breakpoint-down(lg) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row-start: 1;
                grid-row-end: 5;
            }
        }

        .gallery__item--2 {
            grid-column-start: 5;
            grid-column-end: 13;
            grid-row-start: 1;
            grid-row-end: 6;

            @include media-breakpoint-down(lg) {
                grid-column-start: 7;
                grid-column-end: 13;
                grid-row-start: 1;
                grid-row-end: 5;
            }
        }

        .gallery__item--3 {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 4;
            grid-row-end: 11;

            @include media-breakpoint-down(lg) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row-start: 5;
                grid-row-end: 9;
            }
        }

        .gallery__item--4 {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 6;
            grid-row-end: 9;

            @include media-breakpoint-down(lg) {
                grid-column-start: 7;
                grid-column-end: 13;
                grid-row-start: 5;
                grid-row-end: 9;
            }
        }

        .gallery__item--5 {
            grid-column-start: 9;
            grid-column-end: 13;
            grid-row-start: 6;
            grid-row-end: 9;

            @include media-breakpoint-down(lg) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row-start: 9;
                grid-row-end: 13;
            }
        }

        .gallery__item--6 {
            grid-column-start: 5;
            grid-column-end: 13;
            grid-row-start: 9;
            grid-row-end: 14;

            @include media-breakpoint-down(lg) {
                grid-column-start: 7;
                grid-column-end: 13;
                grid-row-start: 9;
                grid-row-end: 13;
            }
        }

        .gallery__item--7 {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 11;
            grid-row-end: 14;

            @include media-breakpoint-down(lg) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row-start: 13;
                grid-row-end: 17;
            }
        }

        .gallery__item--8 {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 14;
            grid-row-end: 18;

            @include media-breakpoint-down(lg) {
                grid-column-start: 7;
                grid-column-end: 13;
                grid-row-start: 13;
                grid-row-end: 17;
            }
        }

        .gallery__item--9 {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 14;
            grid-row-end: 18;

            @include media-breakpoint-down(lg) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row-start: 17;
                grid-row-end: 21;
            }
        }

        .gallery__item--10 {
            grid-column-start: 9;
            grid-column-end: 13;
            grid-row-start: 14;
            grid-row-end: 18;

            @include media-breakpoint-down(lg) {
                grid-column-start: 7;
                grid-column-end: 13;
                grid-row-start: 17;
                grid-row-end: 21;
            }
        }

        .gallery__item--11 {
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 18;
            grid-row-end: 21;

            @include media-breakpoint-down(lg) {
                grid-column-start: 1;
                grid-column-end: 7;
                grid-row-start: 21;
                grid-row-end: 25;
            }
        }

        .gallery__item--12 {
            grid-column-start: 7;
            grid-column-end: 13;
            grid-row-start: 18;
            grid-row-end: 21;

            @include media-breakpoint-down(lg) {
                grid-column-start: 7;
                grid-column-end: 13;
                grid-row-start: 21;
                grid-row-end: 25;
            }
        }
    }
}
