#relais-du-chateau-content {
    &.bg-circle {
        &:before {
            top: 70%;
            height: 600px;
        }
    }

    @include media-breakpoint-down(xs) {
        .slick-carousel-relais-du-chateau-descriptions {
            .slick-prev, .slick-next {
                top: 1%;
            }

            .slick-prev {
                left: 0;
            }

            .slick-next {
                right: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .slick-carousel-relais-du-chateau-images {
            .slick-slide {
                text-align: center;
            }

            img {
                width: 395px !important;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .slick-carousel-relais-du-chateau-images {
            .slick-slide {
                transform: translate(100%, 0);
            }

            .slick-slide:not(.slick-current) {
                img {
                    // filter: grayscale(1);
                    transform: scale(0.75);
                    opacity: 0.5
                }
            }

            .slick-arrow {
                z-index: 10;
            }

            .slick-next {
                right: 14%;

                &:before {
                    content: url("/public/images/icons/chevron-right.png");
                }
            }

            .slick-prev {
                left: 14%;

                &:before {
                    content: url("/public/images/icons/chevron-left.png");
                }
            }

            .slick-dots {
                bottom: -40px;
            }

            .slick-dots li button::before {
                font-size: 10px;
                color: $main-color;
            }
        }
    }

    @include media-breakpoint-down(md) {
        @include media-breakpoint-only(md) {
            .slick-prev {
                left: -35px;
            }

            .slick-next {
                right: -35px;
            }
        }

        .slick-arrow {
            z-index: 10;
        }

        .slick-prev {
            &:before {
                content: url("/public/images/icons/chevron-left.png");
            }
        }

        .slick-next {
            &:before {
                content: url("/public/images/icons/chevron-right.png");
            }
        }

        .slick-dots li button::before {
            font-size: 10px;
            color: $main-color;
        }
    }

    .slick-dotted.slick-slider {
        margin-bottom: 40px;

        .slick-dots {
            bottom: -40px;
        }
    }

    .banner-gift {
        .title-banner {
            text-align: center;
            height: 100%;
            background-color: $main-color-light;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 48px 84px;

            h2 {
                font-size: 32px;
                font-weight: $weightBold;
                color: $main-color;
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    .blue-block {
        text-transform: uppercase;
        color: $main-color;
        padding: 24px;
        border: 1px solid $main-color;

        .infos-course {
            font-size: 24px;
            font-weight: $weightBold;
        }

        .infos-course-price {
            font-size: 20px;
            font-weight: $weightSemiBold;
        }
    }

    .gmaps-adresse {
        border:1px solid #405862;
        width: 550px;
        height: 700px;

        @include media-breakpoint-down(lg) {
            width: 100%;
            height: 400px;
        }
    }
}
