.test-oyat{
    @include oyat-circle-bottom-left;
    .sub-title {
        font-weight: 500;

        strong {
            font-weight: 700;
        }
    }

    .chef-figure {
        @include xs {
            margin-bottom: 20px;
        }
        @include sm {
            margin-bottom: 20px;
        }
        @include md {
        }
        @include lg {
        }
        @include vlg {
        }
    }
    .chef-picture {
        box-shadow: $box-shadow-sm;
    }
}
