.product-infos {
    justify-content: space-between;
    height: 100%;

    & > a {
        height: 100%;

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .card-body {
        flex: inherit;
    }
}

.product-card {
    background-color: transparent;
    height: 100%;

    .product-image {
        position: relative;

        img {
            object-fit: contain;
            max-height: 350px;
            @include sm {
                max-height: 240px;
            }
        }
    }

    .card-body {
        height: 100%;

        .card-content {
            display: flex;
            flex-direction: column;
            justify-content: start;
            height: 100%;

            .sylius-product-name {
                font-size: 16px;
                font-weight: $weightMedium;
                color: $main-color;
                letter-spacing: 2.7px;
            }

            .price {
                font-size: 16px;
                font-weight: $weightMedium;
                color: $oyat-black;
                margin-bottom: 0;
            }
        }
    }

    &:hover {
        text-decoration: none;
    }
}

.sub-products {
    .product-card {
        .product-image {
            img {
                height: 255px;
                @include xs {
                    height: 236px;
                }
                @include sm {
                    height: 240px;
                }
                @include md {
                    height: 350px;
                }
                @include lg {
                    height: 210px;
                }
                @include vlg {
                }
            }
        }
    }
}
