#account-content {
    .list-group {
        .list-group-item {
            background-color: transparent !important;
        }
    }

    .card {
        background-color: transparent !important;
    }

    .btn.btn-outline-primary:hover {
        color: $main-color;
    }

    #sylius-billing-address, #sylius-shipping-address {
        background-color: transparent;

        .card-header {
            background-color: $main-color-light;
        }
    }

    #account-order-list {
        .table {
            min-width: 825px;
        }

        tr {
            border: 1px solid #dee2e6;
        }

        th, td {
            vertical-align: middle;
        }

        th:last-child {
            width: 10%;
            text-align: center;
        }

        th {
            border: none;
        }

        th a {
            color: $black;

            &:hover {
                color: $black;
                text-decoration: underline;
            }

            &:after {
                content: '\f0dc';
                font-family: "Font Awesome 5 Free", sans-serif;
                font-weight: 900;
                padding-left: 6px;
                font-size: 12px;
            }
        }

        .btn-primary:first-child {
            margin-bottom: 0.5rem;
        }

        .btn-primary {
            font-size: 0.77rem;
            line-height: 1.5;
            display: block;
        }
    }

    #account-recipe {
        .card-image {
            margin-bottom: 30px;

            .overlay-top {
                padding: 16px;

                .name {
                    font-size: 16px;
                }
            }
        }
    }
}
