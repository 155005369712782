@mixin doubleBarLinkAnimation() {
    position: relative;

    &:before, &:after {
        content:          '';
        position:         absolute;
        left:             50%;
        transform:        translateX(-50%);
        background-color: $main-color;
        width:            0;
        height:           1px;
        transition:       .2s;
    }

    &:after {
        bottom: 0;
    }

    &:before {
        top: 0;
    }

    @include xs {
        &:before, &:after {
            content: none;
        }
    }
    @include sm {
        &:before, &:after {
            content: none;
        }
    }
    @include md {
        &:before, &:after {
            content: none;
        }
    }
    @include lg {
    }
    @include vlg {
    }

    &:hover {
        &:before, &:after {
            width: 100%;
        }
    }
}

@mixin oyat-circle-bottom-right {
    position: relative;

    &:before {
        content:           '';
        background-image:  url('../../media/logo/circle/circle-51.svg');
        background-repeat: no-repeat;
        //background-size:   cover;
        background-size:   auto 100%;
        display:           block;
        position:          absolute;
        bottom:            -150px;
        right:             0;
        width:             400px;
        height:            400px;
        transform:         translateX(50%);
        z-index:           -1;
    }
}

@mixin oyat-circle-bottom-left {
    position: relative;

    &:before {
        content:           '';
        background-image:  url('../../media/logo/circle/circle-51.svg');
        background-repeat: no-repeat;
        //background-size:   cover;
        background-size:   auto 100%;
        display:           block;
        position:          absolute;
        bottom:            -150px;
        left:              0;
        width:             400px;
        height:            400px;
        transform:         translateX(-50%) rotate(180deg);
        z-index:           -1;
    }
}
