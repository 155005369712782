$noty_type__alert: $main-color;

$noty_type__warning: #FFA400;
$noty_type__warning: #FCF4DE;

$noty_type__error: #E84855;
$noty_type__error: #FCDDDD;

$noty_type__info: #3185FC;
$noty_type__info: #D4E6F9;

$noty_type__success: #20BF55;
$noty_type__success: #E1F5EB;

#noty_layout__topRight {
    @include media-breakpoint-up(lg) {
        top: 180px;
    }
    @include media-breakpoint-down(lg) {
        top: 140px;
    }
}

.noty_theme__mint.noty_bar {
    margin: 4px 0;
    overflow: hidden;
    border-radius: 2px;
    position: relative;

    .noty_body {
        padding: 10px;
        font-size: 14px;
    }

    .noty_buttons {
        padding: 10px;
    }
}

.noty_theme__mint.noty_type__alert,
.noty_theme__mint.noty_type__notification {
    background-color: $noty_type__alert;
    border-bottom: 1px solid darken($noty_type__alert, 20);
    color: $oyat-white;
}

.noty_theme__mint.noty_type__warning {
    background-color: $noty_type__warning;
    border-bottom: 1px solid darken($noty_type__warning, 20);
    color: $oyat-black;
}

.noty_theme__mint.noty_type__error {
    background-color: $noty_type__error;
    border-bottom: 1px solid darken($noty_type__error, 20);
    color: $oyat-black;
}

.noty_theme__mint.noty_type__info,
.noty_theme__mint.noty_type__information {
    background-color: $noty_type__info;
    border-bottom: 1px solid darken($noty_type__info, 20);
    color: $oyat-black;
}

.noty_theme__mint.noty_type__success {
    background-color: $noty_type__success;
    border-bottom: 1px solid darken($noty_type__success, 20);
    color: $oyat-black;
}
