#faq-content {
    h1 {
        letter-spacing: 50px;
        text-indent: 60px
    }

    .accordion .card {
        background-color: transparent;
        border: none;
    }

    .accordion .card-header {
        background-color: transparent;
        border-bottom: 1px solid #A0A0A0;
        display: flex;
        align-items: center;
        padding: 0;

        h2 {
            width: 100%;
            margin-bottom: 0;

            button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 0;
                padding: 24px 0;

                &:after {
                    content: url('/public/images/icons/chevron-bottom_small.png');
                    margin-left: auto;
                    transition: transform 0.3s;
                }

                &.collapsed:after {
                    transform: rotate(-90deg);
                }

                &:hover {
                    color: $main-color;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .accordion .card-body {
        padding: 20px 0;
    }
}
