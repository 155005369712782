.reset-password.bg-circle {
    @include media-breakpoint-down(lg) {
        &:before {
            display: none;
        }
    }
}

#reset-password-content {
    .form-group {
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
        }
    }
}
